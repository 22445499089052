import React, { useState } from "react"
import Layout from "../components/layout"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const IndexPage = () => {
  const [state, setState] = useState({});
  const [formState, setFormState] = useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
  e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setFormState(true))
      .catch((error) => alert(error))
  }

  const thanksBlock =
    <div className={'items-left w-auto'}>
      <h1 style={{fontFamily: 'Geomanist Medium'}} className={'text-7xl mb-2'}>thanks.</h1>
      <p className={'mb-6'}>I'll get back to you within two days. Talk soon.</p>
    </div>

  let contactForm =
    <div className={'items-left w-auto'}>
      <h1 style={{fontFamily: 'Geomanist Medium'}} className={'text-7xl mb-2'}>hi. <span role='img' aria-label='hand wave emoji' className={'text-6xl'}>👋</span></h1>
      <p className={'mb-6'}>Interested in collaborating or learning more about my work? Drop me a note.</p>
      <form
        name='contact'
        method='post'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        onSubmit={handleSubmit}
      >
        <input type='hidden' name="form-name" value="contact" />
        <label>
          <input
            name='name'
            type='email'
            placeholder="Your email*"
            required
            onChange={handleChange}
            style={{fontFamily: 'Geomanist Light'}}
            className={'w-full h-12 rounded mb-4 p-2 tracking-wider bg-white border-2 border-black placeholder-black placeholder-opacity-60'}
          />
        </label>
        <label>
          <textarea
            name='message'
            type='text'
            placeholder="Message*"
            required
            onChange={handleChange}
            rows='20'
            style={{fontFamily: 'Geomanist Light'}}
            className={'w-full h-32 rounded mb-4 p-2 tracking-wider bg-white border-2 border-black placeholder-black placeholder-opacity-60'}
          />
        </label>
        <button
          type="submit"
          style={{fontFamily: 'Geomanist Book'}}
          className={'w-full h-12 bg-black hover:bg-gray-800 text-white rounded tracking-wider transition duration-200 transform'}
          >
          Send
        </button>
      </form>
    </div>

  let contactBlock = formState ? thanksBlock : contactForm

  return (
    <Layout theme='light'>
      <div className={'flex flex-col items-center h-hero text-black'}>
        {contactBlock}
      </div>
    </Layout>
  )
}

export default IndexPage
